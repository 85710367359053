import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

// Inventory Requests
export function getJimjenInvestments2(start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string, user_role_id: number, user_designation: number, start_date: Date, end_date: Date) {
  return axios.get(`${API_URL}/jimjen_investments2_transactions_get`, {
    params: {
      start_limit,
      pagination_size,
      itemto_order,
      desorasc,
      search_term,
      user_role_id,
      user_designation,
      start_date, end_date 

    },
  });
}
