import {useEffect, useState, useRef, useLayoutEffect, useCallback} from 'react'
import {debounce} from 'lodash'

import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {KTCard} from '../../_metronic/helpers'
import {KTCardBody} from '../../_metronic/helpers'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {Maintable} from '../tables/Maintable'
import {useMemo} from 'react'

import LoadingBar from 'react-top-loading-bar'

import {useWindowWidth} from '@react-hook/window-size/throttled'
import { useJimjenInvestments } from '../contextProviders/mpesa_paybills/jimjen_investmentsprovider'
import CountUp from 'react-countup'

import {Column} from 'react-table'
import {useAuth} from '../../app/modules/auth'
import {ItemsSummary} from '../topdashboard/ItemsSummary'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import io from 'socket.io-client'



import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { createColumnHelper } from '@tanstack/react-table'
import { Maintablempesashort } from '../tables/paybilltables/Maintablempesashort'
import { useSkylarkProperties } from '../contextProviders/mpesa_paybills/skylark_propertiesprovider'
import { useJimjenInvestments2 } from '../contextProviders/mpesa_paybills/jimjen_investments2provider'
import { useJennyApartments } from '../contextProviders/mpesa_paybills/jenny_apartmentsprovider'
import {useNavigate } from "react-router-dom";
import { Mpesatransaction } from './modal/Mpesatransaction'




const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
const OPENING_TIME = process.env.REACT_APP_OPENING_TIME

// Hook
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)
dayjs.extend(utc);
dayjs.extend(timezone);

dayjs.tz.setDefault('Africa/Nairobi');
dayjs.locale('en');
const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Dashboard',
    path: '/home',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const urls = [
  'skylark.jimjeninvestments.com',
  'jimjeninvestments2.jimjeninvestments.com',
  'jimjeninvestments.jimjeninvestments.com',
  'jennyapartments.jimjeninvestments.com',
];



const Home = () => {

  const refloadingbar: any = useRef(null)
  const queryClient = useQueryClient()
  const myRefsearch: any = useRef()
  const [mpesatransaction, setMpesatransaction] = useState<any>({})
  const [mpesapayload, setMpesapayload] = useState<boolean>(false)
  const myRefupdate: any = useRef()
  const {currentUser, logout} = useAuth()
  const truncate = require('truncate')
  const navigate = useNavigate(); 
  const [endOfYear, setEndOfYear] = useState<Date>(dayjs().endOf('year').toDate());
  const [startOfYear, setStartOfYear] = useState<Date>(dayjs().startOf('year').toDate());
  const [modalshowmpesa, setModalshowmpesa] = useState(false)
  const [itemto_order, setItemto_order] = useState<string>('transaction_time')
  const [desorasc, setDesorasc] = useState<string>('DESC')
  
  const onlyWidth = useWindowWidth()
 
  const {
    isLoading: jenny_apartmentsisloading,
    isSuccess: jenny_apartmentsdsuccess,
    data: jenny_apartmentsdata,
    status: jenny_apartmentsstatus,
    refetch: jenny_apartmentsrefetch,
    isRefetching: jenny_apartmentsisRefetching,
  }: any = useJennyApartments(
   0,
   5,
    itemto_order,
    desorasc,
    "%",
    Number(currentUser?.user_role_id),
    Number(currentUser?.user_designation_id),
    startOfYear,
    endOfYear

  )


const {
    isLoading: jimjen_investmentsisloading,
    isSuccess: jimjen_investmentsdsuccess,
    data: jimjen_investmentsdata,
    status: jimjen_investmentsstatus,
    refetch: jimjen_investmentsrefetch,
    isRefetching: jimjen_investmentsisRefetching,
  }: any = useJimjenInvestments(
    0,
   5,
    itemto_order,
    desorasc,
    "%",
    Number(currentUser?.user_role_id),
    Number(currentUser?.user_designation_id),
    startOfYear,
    endOfYear
  )



 const {
    isLoading: jimjen_investments2isloading,
    isSuccess: jimjen_investments2dsuccess,
    data: jimjen_investments2data,
    status: jimjen_investments2status,
    refetch: jimjen_investments2refetch,
    isRefetching: jimjen_investments2isRefetching,
  }: any = useJimjenInvestments2(
    0,
   5,
    itemto_order,
    desorasc,
    "%",
    Number(currentUser?.user_role_id),
    Number(currentUser?.user_designation_id),
    startOfYear,
    endOfYear
  )



  const {
    isLoading: skylark_propertiesisloading,
    isSuccess: skylark_propertiesdsuccess,
    data: skylark_propertiesdata,
    status: skylark_propertiesstatus,
    refetch: skylark_propertiesrefetch,
    isRefetching: skylark_propertiesisRefetching,
  }: any = useSkylarkProperties(
    0,
    5,
     itemto_order,
     desorasc,
     "%",
     Number(currentUser?.user_role_id),
     Number(currentUser?.user_designation_id),
     startOfYear,
     endOfYear
  )


type Mpesa_transactions = {
  id: number
  transaction_type: string
  transaction_id: string
  transaction_time: string
  transaction_amount: number
  business_short_code: string
  bill_reference_number: string
  invoice_number: string
  org_account_balance: number
  third_party_transaction_id: string
  msisdn: string
  first_name: string
}


const columnHelper = createColumnHelper<Mpesa_transactions>()
 

 
  const tabledata1 = useMemo(
    () => jenny_apartmentsdata?.data.transactions,
    [jenny_apartmentsdsuccess, jenny_apartmentsdata?.data.transactions]
  )

 const tabledata2 = useMemo(
    () => jimjen_investmentsdata?.data.transactions,
    [jimjen_investmentsdsuccess, jimjen_investmentsdata?.data.transactions]
 )

const tabledata3 = useMemo(
    () => jimjen_investments2data?.data.transactions,
    [jimjen_investments2dsuccess, jimjen_investments2data?.data.transactions]
  )
  


const tabledata4 = useMemo(
    () => skylark_propertiesdata?.data.transactions,
    [skylark_propertiesdsuccess, skylark_propertiesdata?.data.transactions]
  )

 


  useEffect(() => {
    if (mpesatransaction && mpesapayload) {
      setModalshowmpesa(true)
      setMpesapayload(false)
    }
  }, [mpesapayload])




  useEffect(() => {
    // Create a socket connection and event listener for each URL
    const sockets = urls.map(url => {
      const socket = io(url);

      // Define the function to handle the 'update' event for this socket
      const handleSocketUpdate = (notification: any) => {
        (async () => {
          try {
            await refloadingbar.current?.continuousStart();
            await jimjen_investmentsrefetch()
            await jenny_apartmentsrefetch()
            await skylark_propertiesrefetch()
            await jimjen_investments2refetch()
          
            setMpesatransaction(notification)
            setMpesapayload(true)
          } catch (error) {
            console.log(error);
          } finally {
            await refloadingbar.current?.complete();
          }
        })();
      };

      // Attach the event listener to the current socket
      socket.on('update', handleSocketUpdate);

      return { socket, handleSocketUpdate };
    });

    // Cleanup function to remove event listeners and disconnect each socket
    return () => {
      sockets.forEach(({ socket, handleSocketUpdate }) => {
        socket.off('update', handleSocketUpdate);
        socket.disconnect();
      });
    };
  }, []);

  const columndata1 = [
   
   
    columnHelper.accessor('transaction_type', {
      header: 'type',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate'>
              {info.row.original.transaction_id.toUpperCase()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-truncate' title={info.row.original.transaction_type.toUpperCase()}>
              {truncate(info.row.original.transaction_type.toUpperCase(), 15)}
            </span>
          </>
        )
      },
    }),
    columnHelper.accessor('transaction_time', {
      header: () => 'Time',
      cell: (info) => {
        return (
          <>
            <span className='text-dark text-nowrap fw-bolder d-block mb-1 fs-6'>
            {dayjs(info.getValue()).format('LL')}
          </span>
          <span className='text-muted  text-nowrap fw-bold text-muted d-block fs-7'>
          {dayjs(info.getValue()).format('ddd')},   {dayjs(info.getValue()).format('LTS')}
    
          </span>
          </>
        )
      },
    }),
   
    

   

     
    columnHelper.accessor('msisdn', {
      header: 'Mobile No.',
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1'>
            <span className='badge badge-light-primary fs-6'>{info.getValue()} </span>
          </span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {info.row.original.first_name.toUpperCase()}
            </span>
          </>
        )
      },
    }),

    columnHelper.accessor('transaction_amount', {
      header: ()=> <div className='w-100 text-end'>Amount</div>,
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1 text-end'>
            <span className='badge badge-light-primary fs-6'>{addCommas(Number(info.getValue()).toFixed(2))} {CURRENCY_APP}</span>
          </span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              {addCommas(Number(info.row.original.org_account_balance).toFixed(2))} {CURRENCY_APP}
            </span>
          </>
        )
      },
    }),
    
   
  ]
  const columndata3 = [
   
 
    columnHelper.accessor('transaction_type', {
      header: 'type',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate'>
              {info.row.original.transaction_id.toUpperCase()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {info.row.original.transaction_type.toUpperCase()}
            </span>
          </>
        )
      },
    }),
   
   
  
    columnHelper.accessor('transaction_amount', {
      header: ()=> <div className='w-100 text-end'>Amount</div>,
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1 text-end'>
            <span className='badge badge-light-primary fs-6'>{addCommas(Number(info.getValue()).toFixed(2))} {CURRENCY_APP}</span>
          </span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              {addCommas(Number(info.row.original.org_account_balance).toFixed(2))} {CURRENCY_APP}
            </span>
          </>
        )
      },
    }),
    
   
  ]
  const columndata5 = [
   
   
    columnHelper.accessor('transaction_type', {
      header: 'type',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate'>
              {info.row.original.transaction_id.toUpperCase()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {info.row.original.transaction_type.toUpperCase()}
            </span>
          </>
        )
      },
    }),
   
    columnHelper.accessor('msisdn', {
      header: 'Mobile No.',
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1'>
            <span className='badge badge-light-primary fs-6'>{info.getValue()} </span>
          </span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {info.row.original.first_name.toUpperCase()}
            </span>
          </>
        )
      },
    }),

    columnHelper.accessor('transaction_amount', {
      header: ()=> <div className='w-100 text-end'>Amount</div>,
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1 text-end'>
            <span className='badge badge-light-primary fs-6'>{addCommas(Number(info.getValue()).toFixed(2))} {CURRENCY_APP}</span>
          </span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              {addCommas(Number(info.row.original.org_account_balance).toFixed(2))} {CURRENCY_APP}
            </span>
          </>
        )
      },
    }),
    
   
  ]


 
  return (
    <>
      <LoadingBar color='#009ef7' ref={refloadingbar} />
      <PageTitle breadcrumbs={usersBreadcrumbs}>Home</PageTitle>
      {/* begin::Row */}
     
      <div className='row g-5 gx-xxl-8'>
    
        <div className='col-xxl-12'>
          <KTCard>
            <KTCardBody className='py-4'>
              <div className='row mt-4'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                  <div className='mb-2'>
                    <h3 className='fw-bolder my-2'>All Mpesa Paybills</h3>
                  </div>
                </div>
                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                 
                  </div>
                </div>
              </div>

              <div className='row mt-5 mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>

                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                    
                  </div>
                </div>
              </div>
              <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
              <div className='row mt-5 mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                <h5 className='fw-bolder my-2'>Jimjen Investments Ltd 1</h5>
                </div>

                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                  <button className="btn btn-sm btn-primary" type="submit" onClick={()=>{navigate("/mpesa_paybills/jimjen_investments")}}>View All Transactions</button>
                  </div>
                </div>
              </div>
              <Maintablempesashort
                tabledata={jimjen_investmentsdsuccess ? tabledata2 : []}
          
                columndata={
                  onlyWidth <= 576
                    ? columndata3
                    : onlyWidth > 576 && onlyWidth <= 768
                    ? columndata5
                    : onlyWidth > 768 && onlyWidth <= 1200
                    ? columndata5
                    : columndata1
                }
                
                table1loaded={jimjen_investmentsisloading}
                table_status={jimjen_investmentsstatus}
                table_success={jimjen_investmentsdsuccess}
              
                
               
              />
              </div>
              <div className='col-lg-6 fv-row mb-7'>
              <div className='row mt-5 mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                <h5 className='fw-bolder my-2'>Jenny Apartments</h5>
                </div>

                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                  <button className="btn btn-sm btn-primary" type="submit" onClick={()=>{navigate("/mpesa_paybills/jenny_apartments")}}>View All Transactions</button>
                  </div>
                </div>
              </div>
              <Maintablempesashort
                tabledata={jenny_apartmentsdsuccess ? tabledata1 : []}
          
                columndata={
                  onlyWidth <= 576
                    ? columndata3
                    : onlyWidth > 576 && onlyWidth <= 768
                    ? columndata5
                    : onlyWidth > 768 && onlyWidth <= 1200
                    ? columndata5
                    : columndata1
                }
                
                table1loaded={jenny_apartmentsisloading}
                table_status={jenny_apartmentsstatus}
                table_success={jenny_apartmentsdsuccess}
              
                
               
              />
              </div>
              </div>
              <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
              <div className='row mt-5 mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                <h5 className='fw-bolder my-2'>Jimjen Investments Ltd</h5>
                </div>

                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                  <button className="btn btn-sm btn-primary" type="submit" onClick={()=>{navigate("/mpesa_paybills/jimjen_investments2")}}>View All Transactions</button>
                  </div>
                </div>
              </div>
              <Maintablempesashort
                tabledata={jimjen_investments2dsuccess ? tabledata3 : []}
          
                columndata={
                  onlyWidth <= 576
                    ? columndata3
                    : onlyWidth > 576 && onlyWidth <= 768
                    ? columndata5
                    : onlyWidth > 768 && onlyWidth <= 1200
                    ? columndata5
                    : columndata1
                }
                
                table1loaded={jimjen_investments2isloading}
                table_status={jimjen_investments2status}
                table_success={jimjen_investments2dsuccess}
              
                
               
              />
              </div>
              <div className='col-lg-6 fv-row mb-7'>
              <div className='row mt-5 mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                <h5 className='fw-bolder my-2'>Skylark Properties</h5>
                </div>

                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                  <button className="btn btn-sm btn-primary" type="submit" onClick={()=>{navigate("/mpesa_paybills/skylark_properties")}}>View All Transactions</button>
                  </div>
                </div>
              </div>
              <Maintablempesashort
                tabledata={skylark_propertiesdsuccess ? tabledata4 : []}
          
                columndata={
                  onlyWidth <= 576
                    ? columndata3
                    : onlyWidth > 576 && onlyWidth <= 768
                    ? columndata5
                    : onlyWidth > 768 && onlyWidth <= 1200
                    ? columndata5
                    : columndata1
                }
                
                table1loaded={skylark_propertiesisloading}
                table_status={skylark_propertiesstatus}
                table_success={skylark_propertiesdsuccess}
              
                
               
              />
              </div>
              </div>
            </KTCardBody>
          </KTCard>
        </div>
      </div>
     
      
      <Mpesatransaction
        show={modalshowmpesa}
        mpesatransaction={mpesatransaction}
        handleClose={function (): void {
          setMpesatransaction({})
          setModalshowmpesa(false)
        }}
      />
    </>
  )
}

export default Home