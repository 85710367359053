import { useQuery, useMutation } from '@tanstack/react-query';

import { getJennyApartments } from '../../apis/mpesa_paybills/Loadalljenny_apartments';

export const useJennyApartments = (
  start_limit: number,
  pagination_size: number,
  itemto_order: string,
  desorasc: string,
  search_term: string,
  user_role_id: number,
  user_designation: number,
  start_date: Date, 
  end_date: Date
) => useQuery({
  queryKey: ['jennyapartments', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation, start_date, end_date],
  queryFn: () => getJennyApartments(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation, start_date, end_date),
  placeholderData: (previousData: any) => previousData,
  staleTime: 0, 
  refetchOnMount: true, 
  refetchOnWindowFocus: true, 

});

