import {KTSVG} from '../../../_metronic/helpers'
import {FC, useEffect, useState, forwardRef, useImperativeHandle, useRef} from 'react'
import {Modal} from 'react-bootstrap'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import {useFormik, useFormikContext} from 'formik'
import * as Yup from 'yup'
import * as XLSX from 'xlsx'
import clsx from 'clsx'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

type Props = {
  show: boolean
  handleClose: () => void
  ref: any
  setStartOfYear2: any
  setEndOfYear2: any
  tabledata2: any
  tablename: string
}
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS

export const Downloadexcel: FC<Props> = forwardRef((props, ref) => {
  const [loading, setLoading] = useState(false)
  const startOfMonth: any = dayjs().startOf('month').format('YYYY-MM-DD') // First day of the current month
  const endOfMonth: any = dayjs().endOf('month').format('YYYY-MM-DD') // Last day of the current month
  const initialValues = {
    from_date: "",
    to_date: "",
  }

  const eventSchema = Yup.object().shape({
    from_date: Yup.date()
      .required('From date is required')
      .max(Yup.ref('to_date'), 'From date cannot be later than To date'),
    to_date: Yup.date()
      .required('To date is required')
      .min(Yup.ref('from_date'), 'To date cannot be earlier than From date'),
  })
  const formik = useFormik({
    initialValues,
  
    validationSchema: eventSchema,
  
    onSubmit: async (values, {setStatus, setSubmitting, resetForm}) => {
      try {
        setLoading(true); // Start loading indication
        await handleExport(); // Execute export function
        
        // Optional: Perform any additional operations after successful export
      } catch (error) {
        setStatus({success: false, msg: 'Export failed. Please try again.'});
        console.error("Export Error:", error); // Log the error for debugging purposes
      } finally {
        setLoading(false); // Ensure loading is turned off regardless of outcome
        props.handleClose(); // Close the modal form
        resetForm(); // Reset the form values to initial state
      }
    },
  });
  
  useImperativeHandle(ref, () => ({
    Resetmodalvalues() {
      formik.resetForm({})
      props.setStartOfYear2(startOfMonth)
      props.setEndOfYear2(endOfMonth)
      setLoading(false)
    },
  }))

  useEffect(() => {
    
    formik.setValues({
      from_date: startOfMonth,
      to_date: endOfMonth,
      
   })
    formik.validateForm();
    props.setStartOfYear2(startOfMonth)
    props.setEndOfYear2(endOfMonth)
  }, [props.show, startOfMonth, endOfMonth]);

  const handleExport = () => {
    // Convert data to a worksheet
    const worksheet = XLSX.utils.json_to_sheet(formatData(props.tabledata2));
    const workbook = XLSX.utils.book_new();
  
    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');
  
    // Format 'from' and 'to' dates for the file name
    const formattedFromDate = dayjs(formik.values.from_date).format('YYYYMMDD'); // e.g., 20230101
    const formattedToDate = dayjs(formik.values.to_date).format('YYYYMMDD'); // e.g., 20230131
  
    // Construct the file name with 'from' and 'to' dates
    const fileName = `${props.tablename}_Transactions_${formattedFromDate}_to_${formattedToDate}.xlsx`;
  
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, fileName);
};

const formatData = (data: any) => {
    // Map through the data and convert number strings to actual numbers
    return data.map((row: any) => ({
        ...row,
        id: parseInt(row.id),
        transaction_amount: parseFloat(row.transaction_amount),
        org_account_balance: parseFloat(row.org_account_balance),
        transaction_time: dayjs(row.transaction_time).format('DD/MM/YYYY')
    }));
};


  return (
    <Modal
      className='modal fade'
      id='kt_modal_inventorypackaging_form'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={props.show}
      centered
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={props.handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3 className='fw-bolder'>Download Excel</h3>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={props.handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>

        <div className='modal-body'>
          {formik.status && (
            <div className='row mb-4'>
              <div className='col-12 d-flex justify-content-center'>
                <span className='text-danger'>{formik.status}</span>
              </div>
            </div>
          )}
          <form
            id='kt_modal_add_inventorypackaging_form'
            noValidate
            className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
            onSubmit={formik.handleSubmit}
          >
            <div className='row'>
              <div className='col-lg-6 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>From Date</label>
                <input
                  type='date'
                  id='datetimePicker'
                  className='form-control form-control-lg form-control-solid datetime-picker'
                  // Removed defaultValue to rely on value for controlled component behavior
                  value={formik.values.from_date}
                  onChange={(e) => {
                    formik.setFieldValue('from_date', e.target.value)
                    props.setStartOfYear2(e.target.value)
                  }} // Added onChange handler
                  onBlur={formik.handleBlur('from_date')} 
                />
                {formik.touched.from_date && formik.errors.from_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      {/* Check and render error if it's a string */}
                      {typeof formik.errors.from_date === 'string' ? (
                        <span role='alert'>{formik.errors.from_date}</span>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>

              <div className='col-lg-6 fv-row mb-7'>
                <label className='required fw-bold fs-6 mb-2'>To Date</label>
                <input
                  type='date'
                  id='datetimePicker'
                  className='form-control form-control-lg form-control-solid datetime-picker'
                  onChange={(e) => {
                    formik.setFieldValue('to_date', e.target.value)
                    props.setEndOfYear2(e.target.value)
                  }} // Added onChange handler
                  onBlur={formik.handleBlur('to_date')}
                  value={formik.values.to_date}
                />
                {formik.touched.to_date && formik.errors.to_date && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      {/* Check and render error if it's a string */}
                      {typeof formik.errors.to_date === 'string' ? (
                        <span role='alert'>{formik.errors.to_date}</span>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>

        <div className='modal-footer border-0 mt-n5'>
          <button type='button' className='btn btn-light-primary' onClick={props.handleClose}>
            Cancel
          </button>
          <button
            type='submit'
            form='kt_modal_add_inventorypackaging_form'
            className='btn btn-primary'
           
          >
            {!loading && <span className='indicator-label'>Download Excel</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
})
