import React, {useEffect, useState, Dispatch, SetStateAction} from 'react'
import ReactPaginate from 'react-paginate'
import {Mainsubtable} from '../Mainsubtable'
import {Tableloading} from '../Tableloading'
import styled from 'styled-components'

import {
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  expandRows,
  flexRender,
  Column,
  Table,
  ExpandedState,
  getFilteredRowModel,
  getExpandedRowModel,
  ColumnDef,
  SortingState,
} from '@tanstack/react-table'
import {Tableloading2} from '../Tableloading2'
import {KTSVG} from '../../../_metronic/helpers'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'


dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

type Props = {
  tabledata: any
  columndata: any
  table_status: any
  table_success: any
  table1loaded: boolean | undefined
 
 
  
  
}
const API_URL = process.env.REACT_APP_API_URL

const Maintablempesashort: React.FC<Props> = ({
  tabledata,
  columndata,

  table1loaded,
 
 

  
  table_status,
  table_success,
}) => {
  const pageSizes: number[] = [10, 20, 30, 40, 50]
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [expanded, setExpanded] = React.useState<ExpandedState>({})
  const [sorting, setSorting] = React.useState<SortingState>([])
  const canRowExpand = (row: any): boolean => {
    return true
  }
  const tableInstance: any = useReactTable({
    data: tabledata,
    columns: columndata,
    state: {
      expanded,
      sorting,
    },
    onSortingChange: setSorting,
    onExpandedChange: setExpanded,
    getSubRows: (row: any) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
    manualPagination: true,
    manualSorting: true,
   
    manualExpanding: true, // Enable manual expansion
    getRowCanExpand: canRowExpand,
    // Additional configuration as needed...
  })

 

  return (
    <>
      <div className='table-responsive'>
        <table className='table table-hover table-rounded border border-gray-300 gy-5 gs-5'>
          <thead>
            {tableInstance.getHeaderGroups().map((headerGroup: any) => (
              <tr
                key={headerGroup.id}
                className='fw-bolder fs-6 text-gray-800 border-bottom-1 border-gray-300 text-uppercase'
              >
                {headerGroup.headers.map((header: any) => (
                  <th
                    key={header.id}
                    onClick={header.column.getToggleSortingHandler()}
                    className='cursor-pointer select-none'
                    style={{width: header.column.columnDef.meta?.width}}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(header.column.columnDef.header, header.getContext())}

                    
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table1loaded ? (
              <>
                <tr>
                  <td colSpan={tableInstance.getHeaderGroups()[0].headers.length}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center m-5'>
                      <Tableloading />
                    </div>
                  </td>
                </tr>
              </>
            ) : table_success && table_status === 'success' && tabledata.length > 0 ? (
              <>
                {tableInstance.getRowModel().rows.map((row: any) => (
                  // Use React.Fragment to wrap adjacent JSX elements
                  <React.Fragment key={row.id}>
                    <tr>
                      {row.getVisibleCells().map((cell: any) => (
                        <td key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                   
                  </React.Fragment>
                ))}
              </>
            ) : tabledata.length === 0 ? (
              <>
                <tr>
                  <td colSpan={tableInstance.getHeaderGroups()[0].headers.length}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center m-5'>
                      <Tableloading2 />
                    </div>
                  </td>
                </tr>
              </>
            ) : null}
          </tbody>
        </table>
      </div>

     
    </>
  )
}

export {Maintablempesashort}
