const Tableloading = () => {
  const styles: any = {
    borderRadius: '0.475rem',
    boxShadow: '0 0 50px 0 rgb(82 63 105 / 15%)',
    backgroundColor: '#fff',
    color: '#7e8299',
    fontWeight: '500',
    padding: '1rem 2rem',
    textAlign: 'center',
    width: 'auto', // This can be adjusted or removed based on your layout needs
  };

  return <div style={styles}>Processing...</div>;
};
  
  export {Tableloading}