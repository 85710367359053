import { FC, useState, useEffect, createContext, useContext, Dispatch, SetStateAction } from 'react'
import { getUserrole} from '../apis/Loadalltabledata'
import { useAuth } from '../../app/modules/auth'
import { useQuery ,useMutation, keepPreviousData} from '@tanstack/react-query'




// get userrole
export const useUserroles = (user_role_id: number) => useQuery(
  {queryKey: ['userroles'],
  queryFn: () => {
    return getUserrole(Number(user_role_id))
  },
  placeholderData: (previousData, previousQuery) => previousData,
  staleTime: 5 * 60 * 1000, 
}
)
// get userrole









