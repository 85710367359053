import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL
const API_URL2 = process.env.REACT_APP_API_URL_SOCKET



export function getUserrole(user_role_id: number) {

  return axios.get(`${API_URL}/userroles`, { params: {user_role_id}})
}
//get userrole


