import { getJimjenInvestments } from '../../apis/mpesa_paybills/Loadalljimjen_investments'

import { useQuery ,useMutation} from '@tanstack/react-query'



export const useJimjenInvestments = (start_limit: number, pagination_size: number, itemto_order: string, desorasc: string, search_term: string,  user_role_id : number,  user_designation: number,  start_date: Date, end_date: Date) => useQuery(
 {queryKey: ['jimjeninvestments', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation, start_date, end_date],

 queryFn: () => getJimjenInvestments(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id,  user_designation, start_date, end_date), 
 placeholderData: (previousData, previousQuery) => previousData,
 staleTime: 0, 
 refetchOnMount: true, 
 refetchOnWindowFocus: true, 
},
)



