import { useQuery, useMutation } from '@tanstack/react-query';
import { getJimjenInvestments2 } from '../../apis/mpesa_paybills/Loadalljimjen_investments2';

// Use Inventory Requests
export const useJimjenInvestments2 = (
  start_limit: number,
  pagination_size: number,
  itemto_order: string,
  desorasc: string,
  search_term: string,
  user_role_id: number,
  user_designation: number,
  start_date: Date, end_date: Date
) => useQuery({
  queryKey: ['jimjeninvestments2', start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation,  start_date, end_date],
  queryFn: () => getJimjenInvestments2(start_limit, pagination_size, itemto_order, desorasc, search_term, user_role_id, user_designation,  start_date, end_date),
  staleTime: 0, 
  refetchOnMount: true, 
  refetchOnWindowFocus: true, 
});

