import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'

import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import Home from  '../../Jimjen/dashboard/Home'
//mpesa_paybills
import Skylark_properties from '../../Jimjen/mpesa_paybills/Skylark_properties'
import Jimjen_investments2 from '../../Jimjen/mpesa_paybills/Jimjen_investments2'
import Jimjen_investments from '../../Jimjen/mpesa_paybills/Jimjen_investments'
import Jenny_apartments from '../../Jimjen/mpesa_paybills/Jenny_apartments'

//mpesa_paybills


import Norights from "../../Jimjen/norights/Home"


//sales_and_marketing

const PrivateRoutes = () => {
  
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/home' />} />
        {/* Pages */}
        <Route path='home/*' element={<Home  />} />
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='mpesa_paybills/jimjen_investments2' element={<Jimjen_investments2/>} />
        <Route path='mpesa_paybills/jimjen_investments' element={<Jimjen_investments />} />
        <Route path='mpesa_paybills/jenny_apartments' element={<Jenny_apartments />} />
        <Route path='mpesa_paybills/skylark_properties' element={<Skylark_properties/>} />
      
       
  

        <Route path='norights' element={<Norights />} />
        
       
     
      
       
        
        
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}



export {PrivateRoutes}
