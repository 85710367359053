import {KTSVG} from '../../../_metronic/helpers'
import React, {FC, forwardRef, useEffect, useState} from 'react'
import {Modal} from 'react-bootstrap'
import {Column} from 'react-table'
import {useWindowWidth} from '@react-hook/window-size/throttled'
import useNotificationSound from '../../helpers/notifications/useNotificationSound'
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)


type Props = {
  show: boolean
  handleClose: () => void
  mpesatransaction: any
 
}

const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const OPENING_TIME = process.env.REACT_APP_OPENING_TIME

export const Mpesatransaction: FC<Props> = forwardRef((props, ref) => {
 
  const truncate = require('truncate')
  const onlyWidth = useWindowWidth()
  const { playSound } = useNotificationSound({ audioFileUrl: '/media/sound/notification.mp3' });
  
  useEffect(() => {

     if(props.show)
     {
      playSound();
     }
    const timeoutId = setTimeout(() => {
     
      props.handleClose();
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [props.show]);


 

  return (
    <Modal
      className='modal fade'
      id='kt_modal_dashboard'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={props.show}
      centered
      dialogClassName='modal-lg'
      aria-hidden='true'
      onHide={props.handleClose}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h3 className='fw-bolder'>Mpesa Transaction
          <span className='h-10px border-gray-200 border-start mx-2'></span>

           <span className='text-muted fw-bold fs-6'>{props.mpesatransaction.TransID}</span>
          
          </h3>
          

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={props.handleClose}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
        <div className='fv-row mb-7'>
              <div className='card bg-light'>
                {/* begin::Header */}
                <div className='card-header border-0 pt-5'>
                  <h3 className='card-title align-items-start flex-column'>
                    <span className='required card-label fw-bolder fs-5 mb-1'>Transaction Summary</span>
                    <span className='text-muted mt-1 fw-bold fs-7'>
                    Transaction Type: {props.mpesatransaction.TransactionType ? props.mpesatransaction.TransactionType : "Not Available"}
                    </span>
                  </h3>
                  
                </div>
                {/* end::Header */}
                {/* begin::Body */}
                <div className='card-body py-3'>
                  
                You have received <span className='fw-bolder'>{addCommas(Number(props.mpesatransaction?.TransAmount).toFixed(2))} {CURRENCY_APP}</span> from <span className='fw-bolder'>{props.mpesatransaction.FirstName ? props.mpesatransaction.FirstName : "Not Available"}</span>, whose phone number is <span className='fw-bolder'>{props.mpesatransaction.MSISDN ? props.mpesatransaction.MSISDN : "Not Available"}</span>, on <span className='fw-bolder'>{dayjs(String(props.mpesatransaction.TransTime), 'YYYYMMDDHHmmss').locale('en').format('LLLL')}</span>. Their bill reference number is <span className='fw-bolder'>{props.mpesatransaction.BillRefNumber}</span>. Your current account balance for paybill <span className='fw-bolder'>{props.mpesatransaction.BusinessShortCode}</span> is <span className='fw-bolder'>{addCommas(Number(props.mpesatransaction?.OrgAccountBalance).toFixed(2))} {CURRENCY_APP}</span>.
                  <span className='mb-2'></span>
                </div>
                {/* begin::Body */}
              </div>
            </div>

          
        </div>
        <div className='modal-footer border-0 mt-n5'>
          <button type='button' className='btn btn-light-primary' onClick={props.handleClose}>
            Close
          </button>
          
        </div>
      </div>
    </Modal>
  )
})