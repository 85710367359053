import {useEffect, useState, useRef, useLayoutEffect, useCallback} from 'react'
import {PageLink, PageTitle} from '../../_metronic/layout/core'
import {KTCard} from '../../_metronic/helpers'
import {KTCardBody} from '../../_metronic/helpers'
import {KTSVG, toAbsoluteUrl} from '../../_metronic/helpers'
import {useMemo} from 'react'
import LoadingBar from 'react-top-loading-bar'
import {useWindowWidth} from '@react-hook/window-size/throttled'

import CountUp from 'react-countup'

import {createColumnHelper} from '@tanstack/react-table'
import {useAuth} from '../../app/modules/auth'
import {ItemsSummary} from '../topdashboard/ItemsSummary'
import io from 'socket.io-client'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import calendar from 'dayjs/plugin/calendar'
import localizedFormat from 'dayjs/plugin/localizedFormat'



import {queryClient} from '../..'
import { Maintablempesa } from '../tables/paybilltables/Maintablempesa'
import { getJimjenInvestments2 } from '../apis/mpesa_paybills/Loadalljimjen_investments2'
import { useJimjenInvestments2 } from '../contextProviders/mpesa_paybills/jimjen_investments2provider'
import { Downloadexcel } from './modals/Downloadexcel'
import { Mpesatransaction } from './modals/Mpesatransaction'

const CURRENCY_APP = process.env.REACT_APP_CURRENCY
const INFINITE_ROWS = process.env.REACT_APP_INFINITE_ROWS
const OPENING_TIME = process.env.REACT_APP_OPENING_TIME

// Hook
dayjs.extend(relativeTime)
dayjs.extend(calendar)
dayjs.extend(localizedFormat)

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Mpesa Paybill',
    path: '/home',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]
const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
const socket = io('jimjeninvestments2.jimjeninvestments.com')

const Jimjen_investments2 = () => {
  const refloadingbar: any = useRef(null)
  // const queryClient: any = useQueryClient()
  const myRefsearch: any = useRef()
  const myRef: any = useRef()
  const myRef2: any = useRef()
  const {currentUser, logout} = useAuth()
  const truncate = require('truncate')
  const [modalshowmpesa, setModalshowmpesa] = useState(false)
  const [pagination_size, setPagination_size] = useState<number>(10)
  const [mpesatransaction, setMpesatransaction] = useState<any>({})
  const [mpesapayload, setMpesapayload] = useState<boolean>(false)
  const [start_limit, setStart_limit] = useState<number>(0)
  const [statusnow, setStatusnow] = useState<boolean>(false)
  const [statusnow2, setStatusnow2] = useState<boolean>(false)
  const [itemto_order, setItemto_order] = useState<string>('transaction_time')
  const [desorasc, setDesorasc] = useState<string>('DESC')
  const [serversort, setServersort] = useState<any>([])
  const [inventoryitemtodelete, setInventoryitemtodelete] = useState<any>({})
  const [inventoryitemtoupdate, setInventoryitemtoupdate] = useState<any>({})
  const [search_term, setSearch_term] = useState<string>('%')
  const [pageCount, setPageCount] = useState<number>(0)
  const [balancesnew, setBalancesnew] = useState<any>([])
  const [creditdatanew, setCreditdatanew] = useState<any>([])
  const [debitdatanew, setDebitdatanew] = useState<any>([])
  const [modalshowdelete, setModalshowdelete] = useState(false)
  const [endOfYear, setEndOfYear] = useState<Date>(dayjs().endOf('year').toDate());
  const [startOfYear, setStartOfYear] = useState<Date>(dayjs().startOf('year').toDate());
  const [movetofirstpage, setMovetofirstpage] = useState<boolean>(false)
  const [endOfYear2, setEndOfYear2] = useState<Date>(dayjs().endOf('year').toDate());
  const [startOfYear2, setStartOfYear2] = useState<Date>(dayjs().startOf('year').toDate());
  const [modalDownloadExcel, setModalDownloadExcel] = useState(false)
  const [modalUpdateInventory, setModalUpdateInventory] = useState(false)
  const onlyWidth = useWindowWidth()

  //Jimjen Investments Ltd

  const {
    isLoading: jimjen_investments2isloading,
    isSuccess: jimjen_investments2dsuccess,
    data: jimjen_investments2data,
    status: jimjen_investments2status,
    refetch: jimjen_investments2refetch,
    isRefetching: jimjen_investments2isRefetching,
  }: any = useJimjenInvestments2(
    start_limit,
    pagination_size,
    itemto_order,
    desorasc,
    search_term,
    Number(currentUser?.user_role_id),
    Number(currentUser?.user_designation_id),
    startOfYear,
    endOfYear
  )

  const {
    isLoading: jimjen_investments2isloading2,
    isSuccess: jimjen_investments2dsuccess2,
    data: jimjen_investments2data2,
    status: jimjen_investments2status2,
    refetch: jimjen_investments2refetch2,
    isRefetching: jimjen_investments2isRefetching2,
  }: any = useJimjenInvestments2(
    0,
    Number(INFINITE_ROWS),
    itemto_order,
    desorasc,
    search_term,
    Number(currentUser?.user_role_id),
    Number(currentUser?.user_designation_id),
    startOfYear2,
    endOfYear2
  )

  type Mpesa_transactions = {
    id: number
    transaction_type: string
    transaction_id: string
    transaction_time: string
    transaction_amount: number
    business_short_code: string
    bill_reference_number: string
    invoice_number: string
    org_account_balance: number
    third_party_transaction_id: string
    msisdn: string
    first_name: string
  }

  
  useEffect(() => {
    if (mpesatransaction && mpesapayload) {
      setModalshowmpesa(true)
      setMpesapayload(false)
    }
  }, [mpesapayload])

  useEffect(() => {
    const handleValueChange = () => {
      if (myRefsearch.current.value === '' && myRefsearch.current === document.activeElement) {
        setSearch_term('%')
        setPagination_size(10)
        setStart_limit(0)
        setItemto_order('transaction_time')
        setDesorasc('DESC')
        setMovetofirstpage(true)
      }
    }

    const refSearch = myRefsearch.current
    if (refSearch) {
      refSearch.addEventListener('input', handleValueChange)
    }

    return () => {
      if (refSearch) {
        refSearch.removeEventListener('input', handleValueChange)
      }
    }
  }, [])

  //refetching when search tem, order by changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        await refloadingbar.current.continuousStart()
        await jimjen_investments2refetch()
      } catch (error) {
        console.log(error)
      } finally {
        refloadingbar.current.complete()
      }
    }
    fetchData()
  }, [itemto_order, desorasc, search_term])

  useEffect(() => {
    if (serversort.length > 0) {
      const {id, desc} = serversort[0]
      setItemto_order(id)
      setDesorasc(desc ? 'DESC' : 'ASC')
    } else {
      setItemto_order('transaction_time')
      setDesorasc('DESC')
    }
  }, [serversort])

  const tabledata = useMemo(
    () => jimjen_investments2data?.data.transactions,
    [jimjen_investments2dsuccess, jimjen_investments2data?.data.transactions]
  )
  const tabledata2 = useMemo(
    () => jimjen_investments2data2?.data.transactions,
    [jimjen_investments2dsuccess2, jimjen_investments2data2?.data.transactions, startOfYear2, endOfYear2]
  )
  const tabledata3 = useMemo(
    () => jimjen_investments2data2?.data.transactions2 ? jimjen_investments2data2.data.transactions2 : [],
    [jimjen_investments2dsuccess2, jimjen_investments2data2?.data.transactions2]
  )
  
  const tabledatalength = useMemo(
    () => jimjen_investments2data?.data.total_count,
    [jimjen_investments2dsuccess, jimjen_investments2data?.data.total_count]
  )

  const tablename = 'Jimjeninvestments'

  useEffect(() => {
    const fetchData = async () => {
      const nextPage = Math.ceil(start_limit / pagination_size) + 1
      const queryKeyvalue = ['jimjeninvetments2', nextPage * pagination_size, pagination_size]

      // Check if the next page data is already in the cache and is fresh
      const state = queryClient.getQueryState(queryKeyvalue)

      console.log(state)

      if (!state) {
        // If the data is not in the cache or it's stale, then prefetch the next page
        await queryClient.prefetchQuery({
          queryKey: queryKeyvalue,
          queryFn: () =>
            getJimjenInvestments2(
              nextPage * pagination_size,
              pagination_size,
              itemto_order,
              desorasc,
              search_term,
              Number(currentUser?.user_role_id),
              Number(currentUser?.user_designation_id),
              startOfYear,
             endOfYear
            ),
        })
      }
    }

    fetchData()
  }, [
    start_limit,
    pagination_size,
    itemto_order,
    desorasc,
    search_term,
    currentUser?.user_role_id,
    currentUser?.user_designation_id,
    queryClient,
  ])

  const columnHelper = createColumnHelper<Mpesa_transactions>()


  useEffect(() => {
    if (jimjen_investments2dsuccess2) {
      setStatusnow(true)
    } else {
      setStatusnow(false)
    }
  }, [jimjen_investments2dsuccess2])

  useEffect(() => {
    if (
      statusnow && jimjen_investments2isRefetching2
    ) {
      setStatusnow2(true)
    } else {
      setStatusnow2(false)
    }
  }, [jimjen_investments2isRefetching2 , statusnow])

  

  const showModalDownloadExcel = () => {
    setModalDownloadExcel(true)
    if (myRef && myRef.current) {
      myRef.current.Resetmodalvalues()
    }
  }

  const showModaldelete = (x: any) => {
    setModalshowdelete(true)

    setInventoryitemtodelete(x)
  }

  const columndata1 = [
   
    columnHelper.accessor('id', {
      header:()=> <div className='w-100 text-end'>id</div>,
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-end'>
            {info.getValue()}
          </span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
             {info.row.original.business_short_code.toUpperCase()}
            </span>
          </>
        )
      },
    }),
    columnHelper.accessor('transaction_type', {
      header: 'type',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate'>
              {info.row.original.transaction_id.toUpperCase()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7' title={info.row.original.transaction_type.toUpperCase()}>
              {truncate(info.row.original.transaction_type.toUpperCase(), 15)}
            </span>
          </>
        )
      },
    }),
    columnHelper.accessor('transaction_time', {
      header: () => 'Time',
      cell: (info) => {
        return (
          <>
            <span className='text-dark text-nowrap fw-bolder d-block mb-1 fs-6'>
            {dayjs(info.getValue()).format('LL')}
          </span>
          <span className='text-muted  text-nowrap fw-bold text-muted d-block fs-7'>
          {dayjs(info.getValue()).format('ddd')},   {dayjs(info.getValue()).format('LTS')}
    
          </span>
          </>
        )
      },
    }),
   
    

    columnHelper.accessor('bill_reference_number', {
      header:"Reference No.",
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1' title={info.row.original.bill_reference_number}>
            <span className=''>{truncate(info.row.original.bill_reference_number, 20)}</span>
          </span>
          </>
        )
      },
    }),

     
    columnHelper.accessor('msisdn', {
      header: 'Mobile No.',
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1'>
            <span className='badge badge-light-primary fs-6'>{info.getValue()} </span>
          </span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {info.row.original.first_name.toUpperCase()}
            </span>
          </>
        )
      },
    }),

    columnHelper.accessor('transaction_amount', {
      header: ()=> <div className='w-100 text-end'>Amount</div>,
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1 text-end'>
            <span className='badge badge-light-primary fs-6'>{addCommas(Number(info.getValue()).toFixed(2))} {CURRENCY_APP}</span>
          </span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              {addCommas(Number(info.row.original.org_account_balance).toFixed(2))} {CURRENCY_APP}
            </span>
          </>
        )
      },
    }),
    
   
  ]
  const columndata3 = [
   
 
    columnHelper.accessor('transaction_type', {
      header: 'type',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate'>
              {info.row.original.transaction_id.toUpperCase()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {info.row.original.transaction_type.toUpperCase()}
            </span>
          </>
        )
      },
    }),
   
  
    columnHelper.accessor('bill_reference_number', {
      header:"Reference No.",
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1'>
            <span className=''>{info.row.original.bill_reference_number}</span>
          </span>
          </>
        )
      },
    }),

  
    columnHelper.accessor('transaction_amount', {
      header: ()=> <div className='w-100 text-end'>Amount</div>,
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1 text-end'>
            <span className='badge badge-light-primary fs-6'>{addCommas(Number(info.getValue()).toFixed(2))} {CURRENCY_APP}</span>
          </span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              {addCommas(Number(info.row.original.org_account_balance).toFixed(2))} {CURRENCY_APP}
            </span>
          </>
        )
      },
    }),
    
   
  ]
  const columndata5 = [
   
   
    columnHelper.accessor('transaction_type', {
      header: 'type',
      cell: (info: any) => {
        return (
          <>
            <span className='text-darker text-nowrap fw-bold d-block mb-1 fs-6 text-truncate'>
              {info.row.original.transaction_id.toUpperCase()}
            </span>
            <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {info.row.original.transaction_type.toUpperCase()}
            </span>
          </>
        )
      },
    }),
    
   
    

    columnHelper.accessor('bill_reference_number', {
      header:"Reference No.",
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1'>
            <span className=''>{info.row.original.bill_reference_number}</span>
          </span>
          </>
        )
      },
    }),

     
    columnHelper.accessor('msisdn', {
      header: 'Mobile No.',
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1'>
            <span className='badge badge-light-primary fs-6'>{info.getValue()} </span>
          </span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7'>
              {info.row.original.first_name.toUpperCase()}
            </span>
          </>
        )
      },
    }),

    columnHelper.accessor('transaction_amount', {
      header: ()=> <div className='w-100 text-end'>Amount</div>,
      cell: (info) => {
        return (
          <>
          <span className='text-darker text-nowrap fw-bold d-block mb-1 text-end'>
            <span className='badge badge-light-primary fs-6'>{addCommas(Number(info.getValue()).toFixed(2))} {CURRENCY_APP}</span>
          </span>
          <span className='text-muted text-nowrap fw-bold d-block mb-1 fs-7 text-end'>
              {addCommas(Number(info.row.original.org_account_balance).toFixed(2))} {CURRENCY_APP}
            </span>
          </>
        )
      },
    }),
    
   
  ]
 
 
 
  

  const handlesearchKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      const inputValue = event.target.value
      if (inputValue) {
        setSearch_term('%' + inputValue + '%')
      } else {
        setSearch_term('%')
      }
      setPagination_size(10)
      setStart_limit(0)
      setItemto_order('transaction_time')
      setDesorasc('DESC')
      setMovetofirstpage(true)
    }
  }

  const handleinputs = (event: any) => {
    if (myRefsearch.current.value === '' || event.target.value === '') {
      setSearch_term('%')
      setPagination_size(10)
      setStart_limit(0)
      setItemto_order('transaction_time')
      setDesorasc('DESC')
      setMovetofirstpage(true)
    }
  }

  const handlesearchKeyDown2 = (event: any) => {
    event.preventDefault()

    const inputValue = myRefsearch.current.value || event.target.value

    if (inputValue) {
      setSearch_term('%' + inputValue + '%')
    } else {
      setSearch_term('%')
    }

    setPagination_size(10)
    setStart_limit(0)
    setItemto_order('transaction_time')
    setDesorasc('DESC')
    setMovetofirstpage(true)
  }

  useEffect(() => {
    // Define the function that will handle the 'update' event
    const handleSocketUpdate = (notification: any) => {
      ;(async () => {
        try {
          await refloadingbar.current.continuousStart()
          await queryClient.clear()
          await jimjen_investments2refetch()
          setMpesatransaction(notification)
          setMpesapayload(true)
          console.log(notification)
        } catch (error) {
          console.log(error)
        } finally {
          await refloadingbar.current.complete()
        }
      })()
    }

    socket.on('update', handleSocketUpdate)

    return () => {
      socket.off('update', handleSocketUpdate)
    }
  }, [])

  return (
    <>
      <LoadingBar color='#009ef7' ref={refloadingbar} />
      <PageTitle breadcrumbs={usersBreadcrumbs}>Jimjen Investments Ltd</PageTitle>
      {/* begin::Row */}
      {/* begin::Row */}

      <div className='row g-5 g-xl-8'>
        {/* begin::Col */}

        <div className='col-xl-4'>
          <ItemsSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='primary'
            chartHeight='120px'
            cardName='Trans. Amount'
            chartName='Trans. Amount'
            statusnow={statusnow}
            statusnow2={statusnow2}
            yaxisHeight={Math.abs(Math.max(...tabledata3?.map((x: any) => x.sum_transaction_amount)))}
            chartCategories={tabledata3?.sort((a: any, b: any) => (a.transaction_date > b.transaction_date ? 1 : -1))
              .map((x: any) => dayjs(x.transaction_date).format('LL'))}
            chartData={tabledata3?.sort((a: any, b: any) => (a.transaction_date > b.transaction_date ? 1 : -1))
              .map((x: any) => x.sum_transaction_amount)}
            values={
              <>
                <div className='fs-2 fw-bolder d-block '>
                  <CountUp
                    className='text-primary'
                    end={
                      tabledata3?.sort((a: any, b: any) =>
                          a.transaction_date > b.transaction_date ? -1 : 1
                        )
                        .map((x: any) => x.sum_transaction_amount)[0]
                    }
                    duration={2}
                    decimals={2}
                    separator=','
                  />{' '}
                  <sup className='fs-6 text-primary'>{CURRENCY_APP}</sup>
                </div>
              </>
            }
            values2={
              <>
                <span className='fw-bold d-block fs-7 mt-2 text-muted'>
                  <table>
                    <tbody>
                      {tabledata3?.sort((a: any, b: any) =>
                          a.transaction_date > b.transaction_date ? -1 : 1
                        )
                        .map((x: any) => x.transaction_date)[1] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-primary'>
                              {dayjs(
                                tabledata3?.sort((a: any, b: any) =>
                                    a.transaction_date > b.transaction_date ? -1 : 1
                                  )
                                  .map((x: any) => x.transaction_date)[1]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                tabledata3?.sort((a: any, b: any) =>
                                    a.transaction_date > b.transaction_date ? -1 : 1
                                  )
                                  .map((x: any) => x.sum_transaction_amount)[1]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      {tabledata3?.sort((a: any, b: any) =>
                          a.transaction_date > b.transaction_date ? -1 : 1
                        )
                        .map((x: any) => x.transaction_date)[2] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-primary'>
                              {dayjs(
                                tabledata3?.sort((a: any, b: any) =>
                                    a.transaction_date > b.transaction_date ? -1 : 1
                                  )
                                  .map((x: any) => x.transaction_date)[2]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                tabledata3?.sort((a: any, b: any) =>
                                    a.transaction_date > b.transaction_date ? -1 : 1
                                  )
                                  .map((x: any) => x.sum_transaction_amount)[2]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </span>
              </>
            }
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <ItemsSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='info'
            chartHeight='120px'
            cardName='Avg. Amount'
            chartName='Avg. Amount'
            statusnow={statusnow}
            statusnow2={statusnow2}
            yaxisHeight={Math.abs(Math.max(...tabledata3?.map((x: any) => x.average_total_transaction_amount)))}
            chartCategories={tabledata3?.sort((a: any, b: any) => (a.transaction_date > b.transaction_date ? 1 : -1))
              .map((x: any) => dayjs(x.transaction_date).format('LL'))}
            chartData={tabledata3?.sort((a: any, b: any) => (a.transaction_date > b.transaction_date ? 1 : -1))
              .map((x: any) => x.average_total_transaction_amount)}
            values={
              <div className='fs-2 fw-bolder'>
                <CountUp
                  className='text-info'
                  end={
                    tabledata3?.sort((a: any, b: any) =>
                        a.transaction_date > b.transaction_date ? -1 : 1
                      )
                      .map((x: any) => x.average_total_transaction_amount)[0]
                  }
                  duration={2}
                  decimals={2}
                  separator=','
                />{' '}
                <sup className='text-info fs-6'>{CURRENCY_APP}</sup>
              </div>
            }
            values2={
              <>
                <span className='fw-bold d-block fs-7 mt-2 text-muted'>
                  <table>
                    <tbody>
                      {tabledata3?.sort((a: any, b: any) =>
                          a.transaction_date > b.transaction_date ? -1 : 1
                        )
                        .map((x: any) => x.transaction_date)[1] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-info'>
                              {dayjs(
                                tabledata3?.sort((a: any, b: any) =>
                                    a.transaction_date > b.transaction_date ? -1 : 1
                                  )
                                  .map((x: any) => x.transaction_date)[1]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                tabledata3?.sort((a: any, b: any) =>
                                    a.transaction_date > b.transaction_date ? -1 : 1
                                  )
                                  .map((x: any) => x.average_total_transaction_amount)[1]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      {tabledata3?.sort((a: any, b: any) =>
                          a.transaction_date > b.transaction_date ? -1 : 1
                        )
                        .map((x: any) => x.transaction_date)[2] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-info'>
                              {dayjs(
                                tabledata3?.sort((a: any, b: any) =>
                                    a.transaction_date > b.transaction_date ? -1 : 1
                                  )
                                  .map((x: any) => x.transaction_date)[2]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                tabledata3?.sort((a: any, b: any) =>
                                    a.transaction_date > b.transaction_date ? -1 : 1
                                  )
                                  .map((x: any) => x.average_total_transaction_amount)[2]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </span>
              </>
            }
          />
        </div>
        {/* end::Col */}

        {/* begin::Col */}
        <div className='col-xl-4'>
          <ItemsSummary
            className='card-xl-stretch mb-xl-8'
            chartColor='success'
            chartHeight='120px'
            cardName='Balance'
            chartName='Balance'
            statusnow={statusnow}
            statusnow2={statusnow2}
            yaxisHeight={Math.abs(Math.max(...tabledata3?.map((x: any) => x.last_org_account_balance_of_the_day)))}
            chartCategories={tabledata3?.sort((a: any, b: any) => (a.transaction_date > b.transaction_date ? 1 : -1))
              .map((x: any) => dayjs(x.transaction_date).format('LL'))}
            chartData={tabledata3?.sort((a: any, b: any) => (a.transaction_date > b.transaction_date ? 1 : -1))
              .map((x: any) => x.last_org_account_balance_of_the_day)}
            values={
              <div className='fs-2 fw-bolder'>
                <CountUp
                  className='text-success'
                  end={
                    tabledata3?.sort((a: any, b: any) =>
                        a.transaction_date > b.transaction_date ? -1 : 1
                      )
                      .map((x: any) => x.last_org_account_balance_of_the_day)[0]
                  }
                  duration={2}
                  decimals={2}
                  separator=','
                />{' '}
                <sup className='text-success fs-6'>{CURRENCY_APP}</sup>
              </div>
            }
            values2={
              <>
                <span className='fw-bold d-block fs-7 mt-2 text-muted'>
                  <table>
                    <tbody>
                      {tabledata3?.sort((a: any, b: any) =>
                          a.transaction_date > b.transaction_date ? -1 : 1
                        )
                        .map((x: any) => x.transaction_date)[1] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-success'>
                              {dayjs(
                                tabledata3?.sort((a: any, b: any) =>
                                    a.transaction_date > b.transaction_date ? -1 : 1
                                  )
                                  .map((x: any) => x.transaction_date)[1]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                tabledata3?.sort((a: any, b: any) =>
                                    a.transaction_date > b.transaction_date ? -1 : 1
                                  )
                                  .map((x: any) => x.last_org_account_balance_of_the_day)[1]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}

                      {tabledata3?.sort((a: any, b: any) =>
                          a.transaction_date > b.transaction_date ? -1 : 1
                        )
                        .map((x: any) => x.transaction_date)[2] ? (
                        <tr>
                          <td>
                            <span className='badge badge-light-success'>
                              {dayjs(
                               tabledata3?.sort((a: any, b: any) =>
                                    a.transaction_date > b.transaction_date ? -1 : 1
                                  )
                                  .map((x: any) => x.transaction_date)[2]
                              ).calendar(null, {
                                sameDay: '[Today]',
                                nextDay: '[Tomorrow]',
                                nextWeek: 'dddd',
                                lastDay: '[Yesterday]',
                                lastWeek: 'dddd',
                                sameElse: 'DD/MM/YYYY',
                              })}
                            </span>
                          </td>
                          <td>
                            <span className='h-20px border-gray-200 border-start mx-2'></span>
                            <CountUp
                              className='text-bold text-muted'
                              end={
                                tabledata3?.sort((a: any, b: any) =>
                                    a.transaction_date > b.transaction_date ? -1 : 1
                                  )
                                  .map((x: any) => x.last_org_account_balance_of_the_day)[2]
                              }
                              duration={2}
                              decimals={2}
                              separator=','
                            />{' '}
                            <sup className='text-muted'>{CURRENCY_APP}</sup>
                          </td>
                        </tr>
                      ) : (
                        <></>
                      )}
                    </tbody>
                  </table>
                </span>
              </>
            }
          />
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-12'>
          <KTCard>
            <KTCardBody className='py-4'>
              <div className='row mt-4'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'>
                  <div className='mb-2'>
                    <h3 className='fw-bolder my-2'>Jimjen Investments Ltd</h3>
                  </div>
                </div>
                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                    {/* begin::Search */}
                    <div className='d-flex align-items-center position-relative my-1'>
                      <a href='#' onClick={handlesearchKeyDown2}>
                        <KTSVG
                          path='/media/icons/duotune/general/gen021.svg'
                          className='svg-icon-1 position-absolute me-4 top-0 end-0 mt-3 text-primary'
                        />
                      </a>
                      <input
                        type='text'
                        data-kt-user-table-filter='search'
                        className='form-control form-control-solid w-250px ps-14'
                        placeholder='Search Transactions'
                        ref={myRefsearch}
                        // getValue()={searchTitle}
                        onChange={handleinputs}
                        onKeyDown={handlesearchKeyDown}
                      />
                    </div>
                    {/* end::Search */}
                  </div>
                </div>
              </div>

              <div className='row mt-5 mb-5'>
                <div className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>

                <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                  <div className='mb-2'>
                    <a
                      className='btn btn-primary btn-sm mb-2 text-nowrap'
                      onClick={() => {
                        showModalDownloadExcel()
                      }}
                    >
                      <KTSVG
                        path='/media/icons/duotune/files/fil002.svg'
                        className='svg-icon-1'
                      />
                     Download Transactions
                    </a>
                  </div>
                </div>
              </div>

              <Maintablempesa
                tabledata={jimjen_investments2dsuccess ? tabledata : []}
                start_limit={start_limit}
                setStart_limit={setStart_limit}
                pagination_size={pagination_size}
                pageCount={pageCount}
                setPageCount={setPageCount}
                setPagination_size={setPagination_size}
                movetofirstpage={movetofirstpage}
                setMovetofirstpage={setMovetofirstpage}
                columndata={
                  onlyWidth <= 576
                    ? columndata3
                    : onlyWidth > 576 && onlyWidth <= 768
                    ? columndata5
                    : onlyWidth > 768 && onlyWidth <= 1200
                    ? columndata5
                    : columndata1
                }
                
                table1loaded={jimjen_investments2isloading}
                table_status={jimjen_investments2status}
                table_success={jimjen_investments2dsuccess}
              
                tablelength={tabledatalength}
                setServersort={setServersort}
              />
            </KTCardBody>
          </KTCard>
        </div>
      </div>
      <Mpesatransaction
        show={modalshowmpesa}
        mpesatransaction={mpesatransaction}
        handleClose={function (): void {
          setMpesatransaction({})
          setModalshowmpesa(false)
        }}
      />
      <Downloadexcel
        show={modalDownloadExcel}
        handleClose={function (): void {
          setModalDownloadExcel(false)
        }}
        tabledata2={tabledata2}
        setEndOfYear2={setEndOfYear2}
        setStartOfYear2={setStartOfYear2}
        tablename={tablename}
        ref={myRef}
      />

    
    </>
  )
}

export default Jimjen_investments2
function useFocusEffect(arg0: () => void) {
  throw new Error('Function not implemented.')
}

