import { useCallback, useEffect, useRef, useState } from 'react';

type Options = {
  audioFileUrl: string;
};

type Result = {
  playSound: () => void;
  isBufferReady: boolean;
};

const useNotificationSound = ({ audioFileUrl }: Options): Result => {
  const audioContextRef = useRef<AudioContext | null>(null);
  const audioBufferRef = useRef<AudioBuffer | null>(null);
  const [isBufferReady, setIsBufferReady] = useState<boolean>(false);

  const initializeAudio = useCallback(() => {
    if (audioContextRef.current) return;

    const AudioContext = window.AudioContext || (window as any).webkitAudioContext;
    if (!AudioContext) {
      console.error('AudioContext is not supported in this environment');
      return;
    }

    const audioContext = new AudioContext();
    audioContextRef.current = audioContext;

    const request = new XMLHttpRequest();
    request.open('GET', audioFileUrl, true);
    request.responseType = 'arraybuffer';

    request.onload = () => {
      audioContext?.decodeAudioData(request.response, (buffer) => {
        audioBufferRef.current = buffer;
        setIsBufferReady(true);
      }, (error) => {
        console.error('Failed to decode audio data:', error);
      });
    };

    request.onerror = (error) => {
      console.error('Failed to load audio file:', error);
    };

    request.send();
  }, [audioFileUrl]);

  const playSound = useCallback(() => {
    if (!audioContextRef.current) {
      initializeAudio();
    }

    if (!audioBufferRef.current) {
      console.error('Audio buffer is not available');
      return;
    }

    if (audioContextRef.current?.state === 'suspended') {
      audioContextRef.current?.resume().then(() => {
        const audioSource = audioContextRef.current?.createBufferSource();
        if (audioSource && audioContextRef.current) {
          audioSource.buffer = audioBufferRef.current;
          audioSource.connect(audioContextRef.current.destination);
          audioSource.start(0);
        }
      }).catch(error => {
        console.error('Failed to resume the AudioContext:', error);
      });
    } else {
      const audioSource = audioContextRef.current?.createBufferSource();
      if (audioSource && audioContextRef.current) {
        audioSource.buffer = audioBufferRef.current;
        audioSource.connect(audioContextRef.current.destination);
        audioSource.start(0);
      }
    }
  }, [initializeAudio]);

  useEffect(() => {
    return () => {
      audioContextRef.current?.close();
    };
  }, []);

  return {
    playSound,
    isBufferReady,
  };
};

export default useNotificationSound;
