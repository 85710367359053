/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/home'
        icon='/media/icons/duotune/art/art002.svg'
        title={'Main Dashboard'}
        fontIcon='bi-app-indicator'
      />
      {/* Project Management */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted percase fs-8 ls-1'>Enterprise Operations</span>
        </div>
      </div>

      {/* Inventory Management */}

      <AsideMenuItemWithSub
        to='#'
        title='Mpesa Paybills'
        icon='/media/icons/duotune/ecommerce/ecm006.svg'
        fontIcon='bi-box-seam'
      >
        

     
          <AsideMenuItem
            to='/mpesa_paybills/skylark_properties'
            title='Skylark Properties Ltd'
            hasBullet={true}
          />
          
          <AsideMenuItem
            to='/mpesa_paybills/jenny_apartments'
            title='Jenny Apartments'
            hasBullet={true}
          />
        
        <AsideMenuItem
            to='/mpesa_paybills/jimjen_investments2'
            title='Jimjen Investments Ltd'
            hasBullet={true}
          />
        <AsideMenuItem
            to='/mpesa_paybills/jimjen_investments'
            title='Jimjen Investments Ltd 1'
            hasBullet={true}
          />

      </AsideMenuItemWithSub>

      <>
        {/* Client and Supplier Relations Header */}

        {/* Client and Supplier Relations Menu Items */}
        <div className='menu-item'>
          <div className='menu-content pt-8 pb-2'>
            <span className='menu-section text-muted percase fs-8 ls-1'>Support Functions</span>
          </div>
        </div>
      </>

      {/* Reports */}

      <AsideMenuItemWithSub
        to='#'
        title='Reports'
        icon='/media/icons/duotune/general/gen032.svg'
        fontIcon='bi-file-earmark-text'
      >
        <AsideMenuItem
          to='/norights'
          icon='/media/icons/duotune/general/gen025.svg'
          title='Reports Dashboard'
          fontIcon='bi-speedometer'
        />
        <AsideMenuItemWithSub
          to='#'
          title='Reports'
          icon='/media/icons/duotune/general/gen005.svg'
          fontIcon='bi-file-earmark-text'
        >
          <AsideMenuItem to='/norights' title='Financial Reports' hasBullet={true} />

          {/* Add more report items here */}
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>

      {/* System Administration */}
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>System Settings</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='#'
        title='System Administration'
        icon='/media/icons/duotune/coding/cod001.svg'
        fontIcon='bi-gear'
      >
        <AsideMenuItem
          to='/norights'
          icon='/media/icons/duotune/general/gen025.svg'
          title='Admin Dashboard'
          fontIcon='bi-speedometer'
        />
        <AsideMenuItemWithSub
          to='#'
          title='User Administration'
          icon='/media/icons/duotune/general/gen051.svg'
          fontIcon='bi-gear'
        >
          <AsideMenuItem to='/norights' title='User Access Roles' hasBullet={true} />
          <AsideMenuItem to='/norights' title='Users' hasBullet={true} />
          <AsideMenuItem to='/norights' title='User Designation' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
    </>
  )
}
